
import { createWebHistory, createRouter } from "vue-router";

const routes = [
    {
        path: "/",
        name: "layout-page",
        component: () => import('../components/LayoutMain/Layout.vue'),
        children: [
            {
                meta: {
                title: 'Primary care: Supporting Neighbourhood Clinics; Enhancing Health Literacy'},
                path: '/',
                name: "landingPage",
                component: () => import('../views/presignPage/landingPage.vue'),
            },
            {
                
                meta:{title:'Get better consultation experience | Get health care inputs and support'},
                path: '/public',
                name: 'public-Page',
                component: () => import("../views/publicPage.vue"),
            },
            {
                path: '/mobile-footer',
                name: 'Mobile-Footer',
                component: () => import("../views/common-mobile-footer.vue"),
            },
            {
                meta:{title:'Building a social impact initiative'},

                path: '/aboutus',
                name: 'aboutus',
                component: () => import("../views/AboutUs.vue"),
            },

            {
                meta:{title:'Grow your practice | Offer informed consultation'},
                path: '/doctor',
                name: 'Doctor Presign',
                component: () => import("../views/presignPage/presign.vue"),
            },
            {
                meta:{title:'Clinic consultation'},
                path: '/preview/clinic',
                name: 'preview',
                component: () => import("../views/preview-page.vue"),
            },
            {
                meta:{title:'Home consultation'},
                path: '/preview/homeconsultation',
                name: 'homeclinicPreview',
                component: () => import("../views/clinic-preview-page.vue"),
            }
        ]
    },
    {
        path: '/doctor/index',
        redirect: '/doctor/index',
        component: () => import('../components/LayoutMain/layoutDoctor.vue'),
        children: [
            {
                path: '/doctor/index',
                name: "Doctor-Index",
                component: () => import('../views/doctor/doctorHome.vue'),
            },
            {
                path: '/doctor/index:type',
                name: "Doctor-Index-1",
                component: () => import('../views/doctor/doctorHome.vue'),
            },
            {
                path: '/doctor/invite',
                name: "Doctor-Invite",
                component: () => import('../views/doctor/invite/doctorInvite.vue'),
            },
            {
                path: '/doctor/consultationdesk',
                name: "consultation-desk",
                component: () => import('../views/doctor/consulation_desk/consultation_desk.vue'),
            },
            {
                path: '/doctor/report',
                name: "report",
                component: () => import('../views/doctor/consulation_desk/report.vue'),
            },
            {
                path: '/doctor/mobile-settings',
                name: "DoctorMobile-Settings",
                component: () => import('../views/doctor/settings/mobilesetting.vue'),
            },
            {
                path: '/doctor/about-me',
                name: "About-Me",
                component: () => import('../views/doctor/settings/aboutMe.vue'),
            },
            {
                path: '/doctor/practice',
                name: "My-Practice",
                component: () => import('../views/doctor/settings/practice.vue'),
            },
            {
                path: '/doctor/settings',
                name: "Doctor-Settings",
                component: () => import('../views/doctor/settings/doctorSettings.vue'),
            },
            {
                path: '/doctor/consultation-place',
                name: "Consultation-Place",
                component: () => import('../views/doctor/settings/consultationPlace.vue'),
            },

            {
                path: '/doctor/groups',
                name: "Doctor-Groups",
                component: () => import('../views/doctor/settings/doctor-groups.vue'),
            },
            {
                path: '/doctor/settings/:type',
                name: "Doctor-Settings-1",
                component: () => import('../views/doctor/settings/doctorSettings.vue'),
            },
            {
                path: '/doctor/communities-groups',
                name: "Doctor-Settings-2",
                component: () => import('../views/doctor/settings/doctorGroups.vue'),
            },
            {
                path: '/doctor/messaging',
                name: "Doctor-Communication",
                component: () => import('../views/doctor/settings/initialReference.vue'),
            },
            {
                path: '/doctor/message',
                name: "Doctor-Message",
                component: () => import('../views/common/message.vue'),
            },
            {
                path: '/doctor/newmessage',
                name: "Doctor-New-Message",
                component: () => import('../views/common/newmessage.vue'),
            },
            {
                path: '/doctor/messagechat',
                name: "Doctor-Message-Chat",
                component: () => import('../views/common/messagechat.vue'),
            },
            {
                path: '/hcp/newpost',
                name: "Doctor-New-Post",
                component: () => import('../views/common/NewPost.vue'),
            },
            {
                path: '/doctor/library',
                name: "Doctor-Library",
                component: () => import('../views/common/library.vue'),
            },
            {
                path: '/doctor/patientsgroup',
                name: "Doctor-patient-group",
                component: () => import('../views/common/doctor-postPatientGroups.vue'),
            },
            {
                path: '/doctor/doctorcommunities',
                name: "Doctor-doctorcommunity",
                component: () => import('../views/common/doctor-postDoctorCommunity.vue'),
            },
            {
                path: '/doctor/community-groups',
                name: "Doctor-community-and-groups",
                component: () => import('../views/common/doctor-community-and-groups.vue')
            },
            {
                path: '/doctor/footer',
                name: "doctor-footer",
                component: () => import('../views/common/doctor-footer.vue')
            },
            {
                path: '/doctor/knowledgebase',
                name: "Doctor-Knowledge-Base",
                component: () => import('../views/common/knowledgebase.vue'),
            },
            {
                path: '/doctor/manage',
                name: "Doctor-manage",
                component: () => import('../views/common/doctor-manage.vue'),
            },
            {
                path: '/doctor/manage/partner-clinic',
                name: "Doctor-manage-partner-clinic",
                component: () => import('../views/common/manage/partner-clinics.vue'),
            },
            {
                path: '/doctor/manage/create-footwear-order',
                name: "Doctor-manage-create-footwear-order",
                component: () => import('../views/common/manage/create-footwear-order.vue'),

            },
            {
                path: '/doctor/clinic/manage',
                name: "Doctor-clinic-manage",
                component: () => import('../views/common/doctor-clinic-manage.vue'),
            },
            {
                path: '/doctor/clinic/team',
                name: "Doctor-clinic-team",
                component: () => import('../views/common/doctor-clinic-team.vue'),
            },
            {
                path: '/doctor/manage/inventory',
                name: "Doctor-manage-inventory",
                component: () => import('../views/common/doctor-manage/doctor-inventory.vue'),
            },
            {
                path: '/doctor/manage/orders',
                name: "Doctor-manage-orders",
                component: () => import('../views/common/manage/orders.vue'),
            },
            {
                path: '/doctor/manage/accept-orders/:id',
                name: "Doctor-manage-accept-orders",
                component: () => import('../views/common/manage/accept-orders.vue'),
            },
        ]
    },
    {
        path: '/clinicianothers/index',
        redirect: '/clinicianothers/index',
        component: () => import('../components/LayoutMain/layout-clinician-others.vue'),
        children: [
           
            {
                path: '/clinicianothers/index',
                name: "clinicianOthers-Home",
                component: () => import('../views/clinician-others/clinician-others-diary.vue'),
            },
            {
                path: '/clinicianothers/settings',
                name: "clinicianOthers-Settings",
                component: () => import('../views/clinician-others/clinician-others-settings-template.vue'),
            },
            {
                path: '/clinicianothers/footer',
                name: "clinicianOthers-Footer",
                component: () => import('../views/clinician-others/clinician-others-footer.vue')
            },
            {
                path: '/clinicianothers/library',
                name: "clinicianOthers-Library",
                component: () => import('../views/clinician-others/clinician-others-library.vue'),
            },
            {
                path: '/clinicianothers/reports',
                name: "clinicianOthers-reports",
                component: () => import('../views/clinician-others/clinician-others-reports.vue'),
            },
            {
                path: '/clinicianothers/about-me',
                name: "clinicianOthers-about-me",
                component: () => import('../views/clinician-others/clinician-others-Info.vue'),
            },
            {
                path: '/clinicianothers/my-practice',
                name: "clinicianOthers-My Practice",
                component: () => import('../views/clinician-others/clinician-others-myPractice.vue'),
            },
            {
                path: '/clinicianothers/groups',
                name: "clinicianOthers-Groups",
                component: () => import('../views/clinician-others/clinician-others-groups.vue'),
            },
            {
                path: '/clinicianothers/messages',
                name: "clinicianOthers-messages",
                component: () => import('../views/clinician-others/clinician-others-messages.vue'),
            },
            {
                path: '/clinicianothers/consultationdesk',
                name: "clinicianOthers-consultationdesk",
                component: () => import('../views/clinician-others/clinician-others-consultation.vue'),
            },
            {
                path: '/clinicianothers/patient-groups',
                name: "clinicianOthers-patient-groups",
                component: () => import('../views/clinician-others/clinician-others-Patientgroups.vue'),
            },
            {
                path: '/clinicianothers/manage',
                name: "clinicianOthers-manage",
                component: () => import('../views/clinician-others/clinician-others-manage.vue'),
            },
            {
                path: '/clinicianothers/manage/team',
                name: "clinicianOthers-manage-team",
                component: () => import('../views/clinician-others/manage/team.vue'),
            },
            {
                path: '/clinicianothers/manage/partner-clinics',
                name: "clinicianOthers-manage-partner-clinics",
                component: () => import('../views/clinician-others/manage/partner-clinics.vue'),
            },
            {
                path: '/clinicianothers/manage/inventory',
                name: "clinicianOthers-manage-inventory",
                component: () => import('../views/clinician-others/manage/clinician-others-inventory.vue'),
            },
         
            {
                path: '/clinicianothers/mobile-settings',
                name: "clinicianOthersMobile-Settings",
                component: () => import('../views/clinician-others/clinician-others-mobilesettings.vue'),
            },
        ]
    },
    {
        path: "/admin",
        redirect: "/admin/index",
        component: () => import('../components/LayoutMain/layoutAdmin.vue'),
        children: [
            {
                path: '/admin/index',
                name: 'Admin-Index',
                component: () => import('../views/admin/adminIndex.vue')
            },
            {
                path: '/admin/index/:type',
                name: 'Admin-Index1',
                component: () => import('../views/admin/adminIndex.vue')
            },
            {
                path: '/admin/invite',
                name: 'Admin-Invite',
                component: () => import('../views/admin/adminInvite.vue')
            },
            {
                path: '/admin/taskmanager',
                name: "Task-Manager",
                component: () => import("../views/admin/taskManager.vue"),
            },
            {
                path: '/admin/assetmanager',
                name: "Asset-Manager",
                component: () => import("../views/admin/asset-manager/assetManager.vue"),
            },
            {
                path: '/admin/assetmanager/:type',
                name: "Asset-Manager1",
                component: () => import("../views/admin/asset-manager/assetManager.vue"),
            },
            {
                path: '/admin/financemanager',
                name: "Finance-Manager",
                component: () => import("../views/admin/finance/finance-manager.vue"),
            },
            {
                path: '/admin/viewamount',
                name: "view-amount",
                component: () => import("../views/admin/finance/view-amounts.vue"),
            },
            {
                path: '/admin/newpost',
                name: "Admin-New-Post",
                component: () => import('../views/common/NewPost.vue'),
            },
            {
                path: '/admin/clinicmanager',
                name: "Admin-ClinicManager",
                component: () => import('../views/admin/clinicManager.vue'),
            },
            {
                path: '/admin/reports',
                name: "Admin-Reports",
                component: () => import('../views/admin/adminReports.vue'),
            },
            {
                path: '/admin/customers',
                name: "Admin-Customers",
                component: () => import('../views/admin/adminCustomers.vue'),
            },
            {
                path: '/admin/user-drop',
                name: "Admin-User-Drop",
                component: () => import('../views/admin/admin-user-drop.vue'),
            },
        ]
    },
    {
        path: "/user",
        redirect: "/user/index",
        component: () => import('../components/LayoutMain/layout-user.vue'),
        children: [
            {
                path: '/user/index',
                name: 'User-Index',
                component: () => import('../views/user/user-index.vue')
            },
            {
                path: '/homeConsultation/preview',
                name: "homeConsultation-afterSignup",
                component: () => import('../views/doctor/doctorPreview.vue'),
            },
            {
                path: '/user/invite',
                name: 'User-Invite',
                component: () => import('../views/user/user-invite.vue')
            },
            {
                path: '/user/library',
                name: "User-Library",
                component: () => import('../views/user/library/library-invite.vue')
            },
            {
                path: '/user/patientgroups',
                name: "User-patientgroups",
                component: () => import('../views/user/user-postPatientGroups.vue')
            },

            {
                path: '/user/doctorcommunities',
                name: "User-communities",
                component: () => import('../views/user/user-postDoctorCommunity.vue')
            },
            {
                path: '/user/community-groups',
                name: "User-community-and-groups",
                component: () => import('../views/user/user-community-and-groups.vue')
            },
            {
                path: '/user/manage',
                name: "User-manage",
                component: () => import('../views/user/user-manage.vue')
            },
            {
                path: '/user/manage/partner-clinics',
                name: "User-manage-partner-clinic",
                component: () => import('../views/user/manage/partner-clinics.vue')

            },
            {
                path: '/user/manage/inventory',
                name: "User-manage-inventory",
                component: () => import('../views/user/user-manage/user-inventory.vue')
            },
            {
                path: '/user/footer',
                name: "User-footer",
                component: () => import('../views/user/user-footer.vue')
            },
            {
                path: '/user/preview',
                name: "User-Preview",
                component: () => import('../views/user/preview/userPreview.vue'),
            },
            {
                path: '/user/previewcard',
                name: "User-Preview-Card",
                component: () => import('../views/user/preview/previewCard.vue'),
            },
            {
                path: '/user/newpost',
                name: "User-New-Post",
                component: () => import('../views/common/NewPost.vue'),
            },
            {
                path: '/user/publish',
                name: "User-Publish",
                component: () => import('../views/user/publish/publish.vue'),
            },
            {
                path: '/user/request',
                name: "User-Request",
                component: () => import('../views/user/request/request.vue'),
            },
            {
                path: '/user/records',
                name: "User-Records",
                component: () => import('../views/user/records/records.vue'),
            },
            {
                path: '/user/manage-access/:id/:role',
                name: "User-Manage-Access",
                component: () => import('../views/user/records/manage-access/manage-access.vue'),
            },
            {
                path: '/user/upload-record/:id/:role',
                name: "User-Upload-Record",
                component: () => import('../views/user/records/upload-record/upload-record.vue'),
            },
            {
                path: '/user/settings',
                name: "User-Settings",
                component: () => import('../views/user/profile-settings/profile-settings.vue'),
            },
            {
                path: '/user/info',
                name: "User-Info",
                component: () => import('../views/user/profile-settings/my-info/my-info.vue'),
            },
            {
                path: '/user/family',
                name: "User-Family",
                component: () => import('../views/user/profile-settings/my-family/my-family.vue'),
            },
            {
                path: '/user/topic-interest',
                name: "User-Mobile-Topics",
                component: () => import('../views/user/profile-settings/topic-interest/topic-interest.vue'),
            },
            {
                path: '/user/community',
                name: "User-Community",
                component: () => import('../views/user/profile-settings/groups-user/groups-user.vue'),
            },
            {
                path: '/user/message',
                name: "User-Message",
                component: () => import('../views/user/profile-settings/settings/settings.vue'),
            },
            {
                path: '/user/mobile-setting',
                name: "User-MobileSettings",
                component: () => import('../views/user/user-mobile-setting.vue'),
            },
            {
                path: '/user/topic',
                name: "User-Topics",
                component: () => import('../views/user/profile-settings/topicIn.vue'),
            },
            {
                path: '/user/groups',
                name: "User-Groups",
                component: () => import('../views/user/profile-settings/user-groups.vue')
            },
        ]
    },
    {
         path: '/',
         component: () => import ('../components/PresignLayouts/layoutPresign.vue'),
         children: [
            {
                meta:{title:'Search for clinics that are supported by RxIx'},
                path:'/public/searchclinic',
                name:"Public-Searchclinic",
                component:() => import('../views/publicPageCard/searchClinic.vue'),
                
            },
          {
            path:'/public/requestconsultation',
            name:"Public-RequestConsultation",
            component:() => import ('../views/publicPageCard/requestConsultation.vue')
      },
      {
        meta:{title:'Join doctors’ online communities'},
        path:'/public/doctorcommunities',
        name:"Public-JoinDoctor'sCommunity",
        component:() => import ('../views/publicPageCard/JoinDoctorsCommunity.vue')
  },
  {
    meta:{title:'Find strength in patient groups'},
    path:'/public/patientgroups',
    name:"Public-JoinPatentGroups",
    component:() => import ('../views/publicPageCard/joinPatientGroups.vue')
},
  {
    meta:{title:'Access reliable and easy-to-read patient resources'},
    path:'/public/patientresources',
    name:"Public-AccessReliableInformation",
    component:() => import ('../views/publicPageCard/AccessReliableInformation.vue')
}

         ]
    },
    {
        path: '/',
        component: () => import('../components/PresignLayouts/layoutPresign.vue'),
        children: [
            {
                path: '/doctor',
                name: "Presign-Index",
                component: () => import('../views/presignPage/presign.vue'),
            },
            {
                meta:{title:'Join patient communities'},
                path: '/doctor/patientgroups',
                name: "Presign-Joinpatient",
                component: () => import('../views/presignPage/joinPatient.vue'),
            },
            {
                meta:{title:'Join practice-specific communities of doctors'},
                path: '/doctor/communities',
                name: "Presign-Getcase",
                component: () => import('../views/presignPage/getcaseInput.vue'),
            },
            {
                meta:{title:'Offer consultation with better patient info'},
                path: '/doctor/offerconsultation',
                name: "Presign-Offerconsultation",
                component: () => import('../views/presignPage/offerConsultation.vue'),
            },
            {
                path: '/presign/postconsultation',
                name: "Presign-Postconsultation",
                component: () => import('../views/presignPage/postConsultation.vue'),
            },
            {
                meta:{title:'Receive consultation requests without referral fee'},
                path: '/doctor/consultationrequests',
                name: "Presign-Receiveconsultation",
                component: () => import('../views/presignPage/receiveConsultation.vue'),
            },
            {
                meta:{title:'Access reliable and easy-to-read clinical resources for free'},
                path: '/doctor/clinicalresources',
                name: "Presign-Accessclinical",
                component: () => import('../views/presignPage/accessClinical.vue'),
            },
            {
                meta:{title:'Sign up'},
                path: '/signup',
                name: "Presign-Signincommon",
                component: () => import('../views/presignPage/signinCommon')
            },
            {
                meta:{title:'RxIx terms and conditions for healthcare professionals'},
                path: '/terms/doctor',
                name: "Presign-HcpTermsAndCondtion",
                component: () => import('../views/presignPage/hcp-terms-and-condition.vue')
            },
            {
                meta:{title:'RxIx terms and conditions for public'},
                path: '/terms/public',
                name: "Presign-UserTermsAndCondtion",
                component: () => import('../views/presignPage/user-terms-and-condition.vue')
            },
            {
                path: '/presign/community-guidelines',
                name: "Presign-CommnityGuidelines",
                component: () => import('../views/presignPage/community-guidelines.vue')
            },
            {
                meta:{title:'RxIx privacy policy'},
                path: '/terms/privacypolicy',
                name: "Presign-HcpPrivacyPolicy",
                component: () => import('../views/presignPage/privacy-and-policy.vue')
            },
            {
                meta:{title:'Contact us'},
                path: '/contactus',
                name: "Presign-ContactUs",
                component: () => import('../views/presignPage/contact-us.vue')
            }
        ]
    },
    {
        path:'/dss',
        redirect:'/dss/index',
        component:()=> import('../views/common/lib.vue'),
        children:[
            {
                path:'/dss',
                name:"Dss",
                component:()=> import('../views/common/lib.vue'),
            }
        ]
    },

]
const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if(to?.meta?.title){
        const {title } = to.meta;
        document.title = to.meta.title;
        const ogTitleTag = document.querySelector('meta[property="og:title"]');
        ogTitleTag.setAttribute('content', title);
    } else{
    document.title = 'RxIx'
    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    ogTitleTag.setAttribute('content', 'RxIx');
    }
  next();
});

export default router;